const DROPDOWN_CLASS_NAME = "form-select-dropdown";
const DROPDOWN_CLASS_NAME_OPTION = "dropdown-option";
const EXPANDED_CLASS = "expanded";
const MULTIPLE_SELECT = "multiple"

class FormSelectDropdown {
  constructor(element) {
    this.element = element;
    this.dropdownInput = this.element.querySelector("input");
    this.dropdownOption = this.element.querySelector(`.${DROPDOWN_CLASS_NAME_OPTION}`);
    this.items = Array.from(this.dropdownOption.querySelectorAll("li"));
    this.inputHidden = undefined
    this.checkboxs = this.dropdownOption.querySelectorAll('[type="checkbox"]')


    this.element.classList.add("init")
    try {
      this.inputHidden = this.element.querySelector('input[type="hidden"]')
    } catch (error) {
      undefined
    }

    this.element.addEventListener("click", this.toggleDropdown.bind(this), false);
    this.dropdownInput.setAttribute("readonly", "readonly");
    this.itemInitDefaultValue();
    document.addEventListener("click", this.handleDocumentClick.bind(this));
    document.querySelectorAll(`.${DROPDOWN_CLASS_NAME}`).forEach((element) => {
      element.addEventListener("click",  this.handleDocumentClick.bind(this))
    })
    
    this.items.forEach((item) => {
      if (this.dropdownOption.classList.contains(MULTIPLE_SELECT)) {
        item.addEventListener("click", (event) => {
          this.checkboxChangeStatus(item, event)
          this.selectMultiOption(event)}
        );
      } else {
        item.addEventListener("click", () => this.selectOption(item));
      }
    });

    this.checkboxs.forEach(element => {
      element.addEventListener("click", (event) => {
        element.checked = !element.checked
      })
    })
  }

  deleter = () => {
    this.element.removeEventListener("click", this.toggleDropdown.bind(this), false);
    this.items.forEach((item) => {
      item.removeEventListener("click", () => this.selectOption(item));
    });
    document.removeEventListener("click", this.handleDocumentClick.bind(this), false);
  }

  elementCollapse = () => {
    this.element.classList.remove(EXPANDED_CLASS);
    this.dropdownInput.blur();
  };

  itemResetActive = () => {
    this.items.forEach((item) => item.classList.remove("active"));
  };

  getValue = (item) => item.dataset.value || item.innerHTML;
  
  getCheckboxValue = (checkbox) => checkbox.closest('li').dataset.value;
  
  getKey = (item) => item.dataset.key || null; 

  getButton = (item) => item.dataset.btn || null; 

  itemInitDefaultValue = () => {
    this.items.forEach((item) => {
      if (item.classList.contains("active") || item.dataset.default === "true") {
        this.dropdownInput.value = this.getValue(item);
        if (this.inputHidden != null ) {
          this.inputHidden.value = this.getKey(item);
        }
      }
    });
  };

  toggleDropdown = (event=undefined) => {
     if (this.dropdownInput.disabled) {
      return undefined
    }
    this.element.classList.toggle(EXPANDED_CLASS);
    if (event) {
      event.stopPropagation()
    }
  };

  handleDocumentClick = (event) => {
    const isInsideDropdown = this.element.contains(event.target);
    if (!isInsideDropdown) {
      this.elementCollapse();
    }
  };

  selectMultiOption = (event) => { 
    const currentValues = []
    this.checkboxs.forEach(element => {
      if (element.checked) {
        currentValues.push(this.getCheckboxValue(element))
      } 
    })
    this.dropdownInput.value = currentValues.join(', ')
    event.stopPropagation()
  }

  selectOption = (item) => {
    // this.elementCollapse();
    if (this.getButton(item) !== "true"){
      this.itemResetActive();
      item.classList.toggle("active");
      this.dropdownInput.value = this.getValue(item);
      if (this.inputHidden != null ) {
        this.inputHidden.value = this.getKey(item);
      }  
    }
  };

  checkboxChangeStatus = (item, event) => {
    let checkbox = item.querySelector('[type="checkbox"]')
    checkbox.checked = !checkbox.checked
  }
}

function dropdownInit() {

  document.querySelectorAll(`.${DROPDOWN_CLASS_NAME}`).forEach((element) => {
    if (!element.classList.contains("init")) {
      new FormSelectDropdown(element)
    }
  })
}
dropdownInit()

document.addEventListener('htmx:afterOnLoad', (element) => {
  dropdownInit()
})
