
const ATTRIBUTE_NAME = "clickonce"
const EVENT_RESET_NAME = "resetButton"
const EVENT_ALL_RESET_NAME = "resetAllButton"

class OneTimeBtn {
    constructor(element) {
        this.element = element
        this.resetTime = element.dataset.reset
        this.type = element.type
        this.form = element.closest("form")

        this.build()
    }

    build () {
        this.element.removeAttribute(ATTRIBUTE_NAME)
        this.onClick()

        // Event lister nof reset this button
        this.element.addEventListener(EVENT_RESET_NAME, () => {
            this.element.disabled = false
            this.element.classList.remove("disabled")
        })
        // Event lister nof reset all button
        document.addEventListener(EVENT_ALL_RESET_NAME, () => {
            this.element.disabled = false
            this.element.classList.remove("disabled")
        })
    }

    resetClick() {
        if (this.resetTime !== undefined) {
            try {
                setTimeout(() => {
                    this.element.disabled = false
                    this.element.classList.remove("disabled")
                }, Number(this.resetTime));
            } catch (error) {
                console.error(error)
                return undefined
            }
        }
    }

    onClick() {
        this.element.addEventListener('click', () => {
            this.element.disabled = true
            this.element.classList.add("disabled")
            
            if (this.type === "submit") {this.form.submit()}
            if (this.type === "reset") {this.form.reset()}
            
            if (this.element.dataset.reset) {
                this.resetClick()
            }
        })
    }
}



function onetimeBtnInit() {

    document.querySelectorAll(`[${ATTRIBUTE_NAME}]`).forEach(element => {
        new OneTimeBtn(element)
    })
    
  }
  onetimeBtnInit()
  document.addEventListener('htmx:afterOnLoad', (element) => {
    onetimeBtnInit()
  })
  