class PasswordIconBtn {
  constructor(element) {
    this.element = element;
    this.icon = element.children[0];
    this.iconData = this.icon.dataset;
    this.targetElement = document.getElementById(this.iconData.target);

    this.element.addEventListener(
      "click",
      this.togglePasswordVisibility.bind(this)
    );
  }

  togglePasswordVisibility() {
    const visible = this.iconData.visible === "true";
    this.icon.innerHTML = visible ? this.iconData.hide : this.iconData.show;
    this.iconData.visible = visible ? "false" : "true";
    this.targetElement.type = visible ? "password" : "text";
  }
}

class PasswordButton {
  constructor(element) {
    this.element = element;
    this.passwordIcon = document.createElement("span");
    this.inputField = this.element.querySelector("input");

    this.passwordIcon.classList.add("password-icon");
    this.passwordIcon.dataset.show = "false";
    this.passwordIcon.addEventListener("click", this.togglePasswordVisibility);

    this.element.appendChild(this.passwordIcon);
  }

  togglePasswordVisibility = () => {
    const visible = this.passwordIcon.dataset.show === "true";
    this.passwordIcon.classList.toggle("show", !visible);
    this.passwordIcon.dataset.show = visible ? "false" : "true";
    this.inputField.type = visible ? "password" : "text";
  };
}

// Password icon buttons
Array.from(document.querySelectorAll(".password-icon-btn")).map(
  (element) => new PasswordIconBtn(element)
);

// Password buttons
document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll(".form-password")).map(
    (element) => new PasswordButton(element)
  );
});
