import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";

flatpickr.prototype.shortcutButtonsPlugin = ShortcutButtonsPlugin;

function triggerDateRangeChange(element) {
  element.dispatchEvent(new Event('dateRangeChange', { bubbles: true }));
}

const config = {
  mode: "range",
  locale: Italian,
  dateFormat: "d/m/Y",
  defultBtn: 0,
  plugins: [
    new flatpickr.prototype.shortcutButtonsPlugin({
      button: [
        {
          label: "Intervallo",
        },
        {
          label: "Da",
        },
        {
          label: "A",
        },
      ],
      onClick: (index, fp) => {
        fp.pluginElements.map((x) => x.classList.remove("active"));
        let date = new Date();

        switch (index) {
          case 0:
            fp.set("mode", "range");
            date = fp.selectedDates[0] ?? date;
            fp.setDate([undefined, undefined], false);
            break;
          case 1:
            fp.set("mode", "single");
            date = fp.selectedDates[0] ?? date;
            fp.setDate(undefined, false);
            break;
          case 2:
            fp.set("mode", "single");
            date = fp.selectedDates[1] ?? fp.selectedDates[0] ?? date;
            fp.setDate(undefined, false);
            break;
        }

        fp.pluginElements[index].classList.add("active");
        if (index === 1) {
          fp.input.value = `Da ${fp.input.value}`;
        } else if (index === 2) {
          fp.input.value = `A ${fp.input.value}`;
        }
      },
    }),
  ],

  onReady: (selectedDates, dateStr, instance) => {
    const defaultValue = instance.input.defaultValue;
    if (defaultValue.includes("Da")) {
      instance.pluginElements[1].classList.add("active");
      instance.set("mode", "single");
      instance.input.value = `Da ${instance.input.value}`;
    } else if (defaultValue.includes("A")) {
      instance.pluginElements[2].classList.add("active");
      instance.set("mode", "single");
      instance.input.value = `A ${instance.input.value}`;
    } else {
      instance.pluginElements[0].classList.add("active");
    }
  },

  onChange: (selectedDates, dateStr, instance) => {
    instance.pluginElements.forEach((element) => {
      if (element.classList.contains("active")) {
        switch (element.dataset.index) {
          case "0":
            if (instance.selectedDates.length > 1) {
              triggerDateRangeChange(instance.input); 
            }
            break;
          case "1":
            instance.input.value = `Da ${dateStr}`;
            triggerDateRangeChange(instance.input); 
            break;
          case "2":
            instance.input.value = `A ${dateStr}`;
            triggerDateRangeChange(instance.input); 
            break;
        }
      }
    });
  },
  
};

// document.querySelectorAll('input[type="date_flatpikr"]').forEach((element) => {
//   flatpickr(element, config);
// });

function dropdownInit() {

  document.querySelectorAll('input[type="date_flatpikr"]').forEach((element) => {
    flatpickr(element, config)
  })
}
dropdownInit()

document.addEventListener('htmx:afterOnLoad', (element) => {
  dropdownInit()
})


// document.querySelector(".flatpickr-input").addEventListener('dateRangeChange', (element) => {
//   console.log('dateRangeChange')
// })