const ACCORDION_EXPANDED_CLASS = 'expanded'
const ACCORDION_ACTIVE_BTN_CLASS = 'active'
const ACCORDION_NAME = '.nav-accordion'
const ACCORDION_BTN_NAME = 'accordion-btn'
const ACCORDION_MENU_NAME = 'ul'

const ASIDE_NAME = 'aside-navbar-container'
const ASIDE_EXPANDED_CLASS = 'expanded'
const ASIDE_CONTENT_NAME = '.aside-content'

const ANIMATION_DELAY = 0

class AsideNavDropdown {
    constructor (element) {
        this.element = element
        this.button = this.element.querySelector('.' + ACCORDION_BTN_NAME)
        this.menu = this.element.querySelector(ACCORDION_MENU_NAME)
        this.parentGroup = []
        this.listGroup = this.menu.querySelectorAll('.nav-link')

        this.heightElement = window.getComputedStyle(this.element).height

        this.elementStatus = true

        this.button.addEventListener(
            "click",this.toggleDropdown.bind(this)
        );

        this.elementInit()
    }

    getElementStatus = () => {
        return this.element.classList.contains(ACCORDION_EXPANDED_CLASS)
    }

    updateActiveStatus() {
        const hasActiveButton = Array.from(this.listGroup).some((element) =>
          element.classList.contains(ACCORDION_ACTIVE_BTN_CLASS)
        );
    
        if (!this.getElementStatus()) {
          this.button.classList.toggle(ACCORDION_ACTIVE_BTN_CLASS, hasActiveButton);
        } else {
          this.button.classList.remove(ACCORDION_ACTIVE_BTN_CLASS);
        }
      }

    toggleDropdown = () => {
        if (this.getElementStatus()) {
            this.elementCollapse()
            this.elementStatus = false
        } else {
            this.elementExpand()
            this.elementStatus = true
        }
    }

    elementExpand = (collpaseAll = true) => {
        if (collpaseAll) {
            this.parentGroup.forEach(element => element.elementCollapse(update=true))
        }
        this.menu.style.maxHeight = this.heightElement
        this.element.classList.add(ACCORDION_EXPANDED_CLASS)
        this.updateActiveStatus()

    }

    elementCollapse = (update = false) => {
        this.menu.style.maxHeight = '0px'
        this.element.classList.remove(ACCORDION_EXPANDED_CLASS)
        if (update) {this.statusUpdate()}
        this.updateActiveStatus()
    }

    elementInit = () => {
        if (!this.getElementStatus()) {
            this.elementCollapse(update=true)
        }
    }

    statusUpdate = () => {
        this.elementStatus = this.element.classList.contains(ACCORDION_EXPANDED_CLASS)
    }

    softExpand = () => {
        if (this.elementStatus) {
          setTimeout(() => {
            this.elementExpand(collpaseAll = false);
          }, ANIMATION_DELAY);
        }
      }
    
}


class AsideNavBar {
    constructor(element) {
      this.element = element;
      this.content = document.querySelector(ASIDE_CONTENT_NAME)
      this.dropdowns = [];
      this.collapseTimeout = null;
  
      document.addEventListener('DOMContentLoaded', this.createDropdown.bind(this));
  
      if (!this.element.classList.contains(ASIDE_EXPANDED_CLASS)) {
        this.element.addEventListener('mouseover', () => {
          clearTimeout(this.collapseTimeout);
          this.expandNavbar();
        });
        this.element.addEventListener('mouseleave', () => {
          this.collapseTimeout = setTimeout(() => {
            this.collapseNavBar();
          }, ANIMATION_DELAY);
        });
      }
    }
  
    expandNavbar() {
      this.element.classList.add(ASIDE_EXPANDED_CLASS);
      try {this.content.classList.add(ASIDE_EXPANDED_CLASS);} catch {undefined};
      this.dropdowns.forEach((element) => element.softExpand());
    }
  
    collapseNavBar() {
        const collapsibleDropdowns = this.dropdowns.filter(element => element.getElementStatus());
      
        if (collapsibleDropdowns.length > 0) {
          collapsibleDropdowns.forEach(element => element.elementCollapse());
      
          setTimeout(() => {
            this.element.classList.remove(ASIDE_EXPANDED_CLASS);
            try {this.content.classList.remove(ASIDE_EXPANDED_CLASS);} catch {undefined};
          }, ANIMATION_DELAY);
        } else {
          this.element.classList.remove(ASIDE_EXPANDED_CLASS);
          try {this.content.classList.remove(ASIDE_EXPANDED_CLASS);} catch {undefined};
        }
      }

    navGetStatus = () => {
        return this.element.classList.contains(ASIDE_EXPANDED_CLASS);
    }
  
    createDropdown() {
      const dropdowns = this.element.querySelectorAll(ACCORDION_NAME);
      this.dropdowns = Array.from(dropdowns).map((element) => {
        const obj = new AsideNavDropdown(element)
        if (!this.navGetStatus()) {obj.elementCollapse()}
        return obj
    })

      this.dropdowns.forEach((element) => {
        element.parentGroup = this.dropdowns;
      });
    }
  }

document.querySelectorAll('.' + ASIDE_NAME).forEach(element => new AsideNavBar(element))