INPUT_COPY_BUTTON = "[data-copy]"
INPUT_INIT_KEY = "initcopy"
BUTTON_RESET_TIME = 1500


class InputCopyButton {
    constructor(element) {
        this.element = element;
        this.target = this.getInputTarget();

        this.element.dataset[INPUT_INIT_KEY] = 'true';

        this.element.addEventListener('click', this.onClick.bind(this));
    }

    getInputTarget() {
        const targetSelector = this.element.dataset.copy;
        return document.querySelector(targetSelector);
    }

    onClick() {
        this.copyInput()
        this.element.classList.remove('icon-fill')
        this.target.classList.add('is-valid')
        setTimeout(() => {
            this.element.classList.add('icon-fill')
            this.target.classList.remove('is-valid');
        }, BUTTON_RESET_TIME)
    }

    copyInput() {
        this.target.select();
        this.target.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.target.blur();
    }
}

function inputCopyButtonInit() {
    const INPUT_COPY_BUTTON = '[data-copy]';

    document.querySelectorAll(INPUT_COPY_BUTTON).forEach((element) => {
        if (!element.dataset[INPUT_INIT_KEY]) {
            new InputCopyButton(element);
        }
    });
}

inputCopyButtonInit();

document.addEventListener('htmx:afterOnLoad', (element) => {
    inputCopyButtonInit()
})
