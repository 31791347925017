/* 
Bootstap Init 
*/

import Dropdown from './bootstrap/dropdown.js';
import Alert from './bootstrap/alert.js';
import Button from './bootstrap/button.js';
import Carousel from './bootstrap/carousel.js';
import Collapse from './bootstrap/collapse.js';
import Modal from './bootstrap/modal.js';
import Offcanvas from './bootstrap/offcanvas.js';
import ScrollSpy from './bootstrap/scrollspy.js';
import Tab from './bootstrap/tab.js';

import Tooltip from './bootstrap/tooltip.js'
import Popover from './bootstrap/popover.js'
import Toast from './bootstrap/toast.js'


window.Bootstrap = {
  Dropdown,
  Alert,
  Button,
  Carousel,
  Collapse,
  Modal,
  Offcanvas,
  ScrollSpy,
  Tab,
  Tooltip,
  Popover,
  Toast,
};



function InitTooltip() {
  Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach((tooltipTriggerEl) => {
    if (!tooltipTriggerEl.classList.contains("inittooltip")) {
      new Bootstrap.Tooltip(tooltipTriggerEl)
      tooltipTriggerEl.classList.add("inittooltip")
    } 
  })
}

document.addEventListener('htmx:afterOnLoad', (element) => {
  InitTooltip()
})
InitTooltip()
  
Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).map((popoverTriggerEl) => new Bootstrap.Popover(popoverTriggerEl));

Array.from(document.querySelectorAll(".toast")).forEach((toastElement) => {
    const toast = new Bootstrap.Toast(toastElement);
  
    toastElement.addEventListener("hidden.bs.toast", function () {
      toastElement.remove();
    });
  
    toast.show();
  });
  
