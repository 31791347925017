
const progressCircular = document.querySelectorAll('.circular-pregress')


progressCircular.forEach(element => {
    const progressValue = element.querySelector('progress').value
    element.querySelector('.pregress-value').innerHTML = progressValue + "%"

    const elementStyle = window.getComputedStyle(element)

    const styleSplit = elementStyle.background.split('conic-gradient(')
    const styleGradient = styleSplit[1].split('deg)')
    const styleBgGradinet = styleGradient[0].split(') ')
    const styleBgGradinetColor2 = styleBgGradinet[1].split('deg,')[1]

    const styleOutput = `conic-gradient(${styleBgGradinet[0]}) ${(progressValue / 100) * 360}deg,${styleBgGradinetColor2}) ${styleBgGradinet[2]}deg)`

    element.style.background = styleOutput
})
