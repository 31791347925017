const DROPDOWN_CLASS_NAME = "form-search-dropdown";
const DROPDOWN_CLASS_NAME_OPTION = "dropdown-option";

const EXPANDED_CLASS = "expanded";


class FormSelectDropdownSearch {
    constructor(element) {
        this.element = element;
        this.dropdownInput = this.element.querySelector("input");
        this.dropdownOption = this.element.querySelector(
            "." + DROPDOWN_CLASS_NAME_OPTION
        );

        try {
            this.inputHidden = this.element.querySelector('input[type="hidden"]')
        } catch (error) {
            undefined
        }

        this.dropdownInput.addEventListener(
            "click",
            this.toggleDropdown.bind(this)
        );
        this.dropdownInput.addEventListener(
            "keyup",
            this.openDropdown.bind(this)
        );
        document.addEventListener("click", this.handleDocumentClick.bind(this));

        Array.from(this.dropdownOption.querySelectorAll("li")).map((item) => {
            item.addEventListener("click", () => this.selectOption(item));
        });
    }

    elementCollapse = () => {
        this.element.classList.remove(EXPANDED_CLASS);
    };

    toggleDropdown = () => {
        this.element.classList.toggle("expanded");
    };

    openDropdown = () => {
        this.element.classList.add("expanded");
    };

    getKey = (item) => item.dataset.key || null; 

    handleDocumentClick = (event) => {
        const isInsideDropdown = this.element.contains(event.target);
        if (!isInsideDropdown) {
            this.elementCollapse();
        }
    };

    selectOption = (item) => {
        this.elementCollapse();
        this.dropdownInput.value = item.dataset.value || item.innerHTML;
        if (this.inputHidden != null ) {
            this.inputHidden.value = this.getKey(item);
          }  
    };
}

Array.from(document.querySelectorAll(`.${DROPDOWN_CLASS_NAME}`)).map(
    (element) => new FormSelectDropdownSearch(element)
);


// function dropdownInit() {
//     Array.from(document.querySelectorAll("." + DROPDOWN_CLASS_NAME)).map((element) => new FormSelectDropdown(element));
//   }
// dropdownInit()

// document.addEventListener('htmx:afterRequest', () => {
//     dropdownInit()
// })

// document.addEventListener('htmx:load', () => {
//     dropdownInit()
// })